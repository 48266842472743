import { call, takeEvery, put, all, select } from "redux-saga/effects";
import * as API from "./services";
import actions, { setState } from "./actions";
import Swal from "sweetalert2";
import { zohoPostLead } from "./services";

const getEstimationState = (state) => state.estimation;

function* GET_ESTIMATION({ payload }) {
  try {
    const response = yield call(API.getEstimation, payload.address);
    if (response || response.length === 0) {
      payload.navigate("/estimate-form");
    }
    yield put({
      type: actions.SET_STATE,
      payload: {
        estimation: response || [],
        address: payload.address,
      },
    });
  } catch (e) {
    console.log("getLatestEstimation", e);
  }
}

function* EVALUER_BIEN({ payload }) {
  const response = yield call(API.evaluerBien, payload);
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        evaluerBien: response,
        ficheEstimation: response.ficheEstimation,
      },
    });
  } else {
    console.log("error : ", response);
  }
}

function* HANDLE_SEND_CODE({ payload }) {
  const { phoneNumber, recaptchaVerifier } = payload;
  const response = yield call(
    API.handleSendCode,
    phoneNumber,
    recaptchaVerifier
  );
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        verificationId: response,
        showModal: true,
      },
    });
  } else {
    console.log("error");
  }
}

// function* HANDLE_VERIFY_CODE({ payload }) {
//   try {
//     const { navigate, data, result, verificationCode } = payload;
//     console.log("run HANDLE_VERIFY_CODE");
//     console.log("payload", payload);
//     let { verificationId, ficheEstimation } = yield select(getEstimationState);
//     const credentials = yield call(
//       API.handleVerifyCode,
//       verificationId,
//       verificationCode
//     );
//     let newUser = false;
//     yield call(setState, {
//       loading: true,
//     });
//     const uid = yield call(API.signInWithCredential, credentials);
//     if (!uid) {
//       //TODO: add error message
//       return false;
//     }
//     let user = yield call(API.getUserWithUID, uid);
//     if (!user) {
//       user = {
//         userId: uid,
//         role: "membre",
//         displayName: `${data.firstName} ${data.lastName}`,
//         phoneNumber: data.telephone,
//         email: data.email,
//       };
//       newUser = true;
//     }
//     yield call(API.updateUserWithUID, uid, user);
//     ficheEstimation = {
//       ...ficheEstimation,
//       userID: uid,
//       userEmail: data.email,
//       phoneNumber: data.telephone,
//       displayName: `${data.firstName} ${data.lastName}`,
//       duplicated: false,
//       createdAt: new Date(),
//     };
//     const evaluationId = yield call(
//       API.createRapportEvaluation,
//       ficheEstimation
//     );
//     if (newUser) {
//       const lead_data = {
//         info: {
//           data: [
//             {
//               Last_Name: `${data.firstName} ${data.lastName}`,
//               Email: data.email,
//               Phone: data.telephone,
//               ConnectionSource: "lp",
//             },
//           ],
//         },
//         vmodule: "Leads",
//       };

//       zohoPostLead(lead_data)
//         .then((res) => {
//           console.log("data sent to zoho");
//         })
//         .catch((err) => {
//           console.log("err", err);
//         });
//       navigate("/vmz/" + evaluationId);
//     } else {
//       navigate("/redirectUrl", {
//         state: { id: evaluationId },
//       });
//     }
//     if (result?.courtiers && result?.courtiers.length > 0) {
//       const userData = yield call(API.getUserWithUID, uid);
//       let courtiersRecommandes = userData.recommandations;
//       const currentCourtiersRecommandes = JSON.parse(
//         JSON.stringify(result?.courtiers)
//       );
//       if (!courtiersRecommandes) {
//         courtiersRecommandes = currentCourtiersRecommandes;
//       } else if (
//         currentCourtiersRecommandes &&
//         currentCourtiersRecommandes.length !== 0
//       ) {
//         for (let i = 0; i < currentCourtiersRecommandes.length; i++) {
//           if (
//             courtiersRecommandes &&
//             courtiersRecommandes.length !== 0 &&
//             courtiersRecommandes.some(
//               (item) =>
//                 item?.numeroPermis ===
//                 currentCourtiersRecommandes[i].numeroPermis
//             ) === false
//           ) {
//             if (courtiersRecommandes.length < 4) {
//               courtiersRecommandes.push(currentCourtiersRecommandes[i]);
//             } else {
//               courtiersRecommandes.shift();
//               courtiersRecommandes.push(currentCourtiersRecommandes[i]);
//             }
//           }
//         }
//       }
//       yield call(API.updateUserWithUID, {
//         recommandations: courtiersRecommandes,
//       });
//     }
//   } catch (e) {
//     Swal.fire("Oups erreur!", e?.message, "error");
//     yield put({
//       type: actions.SET_STATE,
//       payload: {
//         loading: false,
//         showModal: false,
//       },
//     });
//   }
// }

function* estimationSagas() {
  yield all([
    takeEvery(actions.GET_ESTIMATION, GET_ESTIMATION),
    takeEvery(actions.EVALUER_BIEN, EVALUER_BIEN),
    takeEvery(actions.HANDLE_SEND_CODE, HANDLE_SEND_CODE),
    // takeEvery(actions.HANDLE_VERIFY_CODE, HANDLE_VERIFY_CODE),
  ]);
}

function convertTZ(date, tzString) {
  return new Date(
    (typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", {
      timeZone: tzString,
    })
  );
}

function getDate() {
  const todayDate = new Date();
  const date = convertTZ(todayDate, "America/Blanc-Sablon");
  const [month, day, year] = [
    ("0" + (date.getMonth() + 1)).slice(-2),
    ("0" + date.getDate()).slice(-2),
    date.getFullYear(),
  ];
  const [hour, minutes, seconds] = [
    ("0" + date.getHours()).slice(-2),
    ("0" + date.getMinutes()).slice(-2),
    ("0" + date.getSeconds()).slice(-2),
  ];

  return `${year}-${month}-${day}T${hour}:${minutes}:${seconds}-04:00`;
}

function getEvalReport(
  evaluationId,
  formValues,
  indicePercent,
  location,
  data,
  result
) {
  return {
    info: {
      data: [
        {
          Evaluation_ID: evaluationId,
          SellToFinancePurchase: formValues?.projectionFinancement,
          PlanningToSell: formValues?.envisageVendreBienReponse,
          Bank_Prequalification: formValues?.emprunter,
          Bathrooms: formValues?.bains,
          Bedrooms: formValues?.chambres,
          Category_Property: formValues?.type,
          City: location.city,
          Confidence_Index: indicePercent,
          Email_Seller_Buyer: data?.email,
          Raison_estimation: formValues?.raisonEstimation,
          Address_Evaluation: location.value,
          Date_Evaluation: getDate(),
          Garage: formValues?.garages || "",
          General_Property_Condition: formValues?.standing,
          HowAreYouSelling: formValues?.ouiCommenceVenteReponse,
          Municipal_Assessment: result?.evaluationMunicipale,
          Municipal_Tax: result?.taxesMunicipale,
          Name: `${data.firstName} ${data.lastName}`,
          Parking_slots: formValues?.stationnement || 0,
          Phone_Seller_Buyer: data.telephone,
          Property_category: formValues?.type,
          Property_owner_flag: formValues?.estProprietaireReponse,
          Region: location.state.long_name,
          Rooms: formValues?.pieces || 0,
          Search_Status: formValues?.statutRecherche,
          Surface_in_square_feet: formValues?.superficie,
          This_property_is: formValues?.ceBienEstReponse,
          Type_of_bulding: formValues?.typeBatiment,
          Type_propriete: formValues?.genreProprietes,
          VM_Zia: result?.ziaEstimation?.prediction,
          VMZ_maxi: result?.ziaEstimation?.predictionEnd,
          VMZ_mini: result?.ziaEstimation?.predictionStart,
          WillingToBeContacted: formValues?.ouiContacterParProfessionnel,
          Year_of_contruction: formValues?.anneeConstruction,
          Code_postal: location.postcode,

          Courtiers_recommandes:
            result?.courtiers?.length !== 0
              ? result?.courtiers.map((v) => ({
                  Courtier_Agence: v.agenceCourtier,
                  Courtier_Nom: v.nomCourtier,
                  Courtier_Total_ventes: v.nbVentesTotal,
                  Logo_Agence_URL: v.logoAgence || "",
                  Photo_URL: v.Photo || "",
                  Courtier_License_Number: v.numeroPermis,
                }))
              : "",
          Comparables:
            result?.comparables &&
            Array.isArray(result?.comparables) &&
            result?.comparables?.length !== 0
              ? result?.comparables?.map((v) => ({
                  Comparables_address: v.adresse,
                  Comparables_Bathrooms: v.salleBains,
                  Comparables_Bedrooms: v.nbrChambres,
                  Comparables_Rooms: v.nbrPieces,
                  Comparables_Similarity_score: (v.score * 100).toFixed(2),
                  Comparables_Surface: v.superficieTerrain,
                  Comparables_Type_of_building: v.typeBatiment,
                }))
              : "",
        },
      ],
    },
    vmodule: "RapportEval",
  };
}

export default estimationSagas;
